<template>
  <div class="navigation-wrap">
    <b-card>
      <div class="page-title">
        <p>QUẢN LÝ ĐIỀU HƯỚNG</p>
        <b-button
          size="sm"
          variant="primary"
          @click="resetForm"
        >
          <feather-icon icon="PlusIcon" />
          Thêm mới
        </b-button>
      </div>
      <hr>
      <div class="row mt-2">
        <div class="col-4">
          <div class="navigation-area">
            <div class="nav-title">
              ỨNG DỤNG
            </div>
            <div class="pt-1 pb-1 text-center">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                :class="{'app-disabled': appType}"
                @click="changeApp(false)"
              >
                Quản trị một cửa
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="mr-1 margin-md"
                :class="{'app-disabled': !appType}"
                @click="changeApp(true)"
              >
                Cổng dịch vụ công
              </b-button>
            </div>
          </div>
          <div class="navigation-area mt-2">
            <div class="nav-title">
              DANH SÁCH ĐIỀU HƯỚNG
            </div>
            <div class="d-flex mt-1 mb-1 search-group">
              <input
                v-model="searchText"
                class="filter-input"
                placeholder="Tìm kiếm"
                @keyup.enter="filterNav"
              >
              <feather-icon
                icon="SearchIcon"
                @click="filterNav"
              />
            </div>
            <hr>
            <div class="nav-tree">
              <nav-tree
                :options="treeOptionNav"
                :set-nav-item="setNavItem"
              />
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="navigation-area">
            <div class="nav-title">
              THÔNG TIN ĐIỀU HƯỚNG
            </div>
            <validation-observer
              ref="navigationRules"
            >
              <div class="row mt-2">
                <div class="col-sm-12 col-lg-6">
                  <b-form-group
                    label="Điều hướng Id"
                  >
                    <b-form-input
                      id="title"
                      v-model="navigation.id"
                      type="text"
                      disabled
                      placeholder=""
                    />
                  </b-form-group>
                  <b-form-group
                    label="Tên điều hướng"
                    label-for="input-1"
                    class="required"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      key="tenDieuHuong"
                      :rules="{ required: true }"
                      name="tenDieuHuong"
                    >
                      <b-form-input
                        id="input-1"
                        ref="tenDieuHuong"
                        v-model="navigation.name"
                        :class="{required: errors.length > 0}"
                        type="text"
                        placeholder="Nhập tên điều hướng"
                        required
                      />
                      <span class="label-noti-validate">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <b-form-group
                    label="Tên điều hướng cha"
                  >
                    <treeselect
                      id="linhVuc"
                      v-model="navigation.parentId"
                      v-format-v-select
                      :options="treeOptionNav"
                      :limit="0"
                      :limit-text="(count) => ` + ${count} lựa chọn`"
                      :default-expand-level="1"
                      no-options-text="Không có dữ liệu"
                      no-results-text="Không có dữ liệu"
                      placeholder="Chọn điều hướng cha"
                      :clear-on-select="true"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Mã điều hướng"
                    label-for="input-1"
                    class="required"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      key="maDieuHuong"
                      :rules="{ required: true }"
                      name="maDieuHuong"
                    >
                      <b-form-input
                        ref="maDieuHuong"
                        v-model="navigation.code"
                        type="text"
                        :class="{required: errors.length > 0}"
                        placeholder="Nhập mã điều hướng"
                        required
                      />
                      <span class="label-noti-validate">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group
                    label="Đường dẫn"
                  >
                    <b-form-input
                      id="title"
                      v-model="navigation.urlRewrite"
                      type="text"
                      placeholder=""
                    />
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="d-flex">
                    <b-form-group
                      label="Thứ tự"
                      class="required w-50"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        key="soThuTu"
                        :rules="{ required: true }"
                        name="soThuTu"
                      >
                        <b-form-input
                          ref="soThuTu"
                          v-model="navigation.order"
                          :class="{required: errors.length > 0}"
                          type="text"
                          required
                        />
                        <span class="label-noti-validate">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Trạng thái"
                      class="w-50 pl-2"
                    >
                      <b-form-checkbox
                        v-model="navigation.status"
                        class="text-tip"
                      >
                        <b-button
                          v-if="navigation.status"
                          size="sm"
                          variant="primary"
                          class="button-small"
                        >
                          Hoạt động
                        </b-button>
                        <b-button
                          v-else
                          size="sm"
                          variant="danger"
                          class="button-small"
                        >
                          Không hoạt động
                        </b-button>
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <b-form-group
                    label="Mã biểu tượng"
                  >
                    <treeselect
                      v-model="navigation.iconClass"
                      :options="icons"
                      :limit="0"
                      :limit-text="(count) => ` + ${count} lựa chọn`"
                      no-options-text="Không có dữ liệu"
                      no-results-text="Không có dữ liệu"
                      placeholder="Chọn mã biểu tượng"
                      :clear-on-select="true"
                    >
                      <div
                        slot="value-label"
                        slot-scope="{ node }"
                      >
                        <img
                          :src="require(`@/assets/images/menu/${node.id}`)"
                          style="width: 20px; height: 20px"
                        >
                      </div>
                      <label
                        slot="option-label"
                        slot-scope="{ node, shouldShowCount, count, labelClassName }"
                        :class="labelClassName"
                      >
                        <img
                          :src="require(`@/assets/images/menu/${node.id}`)"
                          style="width: 20px; height: 20px"
                        >
                      </label>
                    </treeselect>
                  </b-form-group>
                </div>
              </div>
            </validation-observer>
            <b-button
              size="sm"
              variant="primary"
              class="mr-1"
              @click="saveForm"
            >
              <feather-icon icon="SaveIcon" />
              Lưu
            </b-button>
            <b-button
              v-if="!isCreate"
              size="sm"
              variant="danger"
              @click="deleteNavigation"
            >
              <feather-icon icon="Trash2Icon" />
              Xoá
            </b-button>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import _cloneDeep from 'lodash/cloneDeep'
import END_POINTS from '@/api/endpoints'
import { mapGetters } from 'vuex'
import NavTree from '@/modules/quan-tri-he-thong/components/pages/navigations/NavTree.vue'
import { removeDiacritical } from '@/utils/index'
import { FONT_AWESOME } from '../../../constants/constants'

const NAV_MODEL = {
  id: '',
  parentId: null,
  name: '',
  code: '',
  urlRewrite: '',
  iconClass: null,
  order: 1,
  status: true,
}

const QUAN_TRI_MOT_CUA_ID = '00000000-0000-0000-0000-000000000001'
const DICH_VU_CONG = '00000000-0000-0000-0000-000000000002'

export default {
  name: 'QuanLyDieuHuong',
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    NavTree,
  },
  data() {
    return {
      required,
      navigation: _cloneDeep(NAV_MODEL),
      icons: FONT_AWESOME.map(icon => ({
        id: icon,
        label: icon,
      })),
      appType: false,
      isCreate: true,
      navigationsTree: [],
      searchText: '',
    }
  },
  computed: {
    ...mapGetters({
      navigations: 'common/navigations',
    }),
    treeOptionNav() {
      return this.treeMapNavigations(this.navigationsTree)
    },
  },
  created() {
    this.layNavigations()
  },
  methods: {
    treeMapNavigations(navigations) {
      const getChildren = Children => Children.map(child => {
        let result = {
          id: child.id,
          label: child.name,
          model: child,
          level: child.level,
        }
        if (child.subChild.length > 0) {
          result = {
            ...result,
            children: getChildren(child.subChild),
          }
        }
        return result
      })
      return getChildren(navigations)
    },
    resetForm() {
      this.isCreate = true
      this.navigation = _cloneDeep(NAV_MODEL)
      this.$refs.navigationRules.reset()
    },
    findNavById(id) {
      let result = null
      const searchNav = navigations => {
        const nav = navigations.find(item => item.id === id)
        if (nav) {
          result = nav
        } else {
          const arrlength = navigations.length
          let res = null
          for (let i = 0; i < arrlength; i += 1) {
            if (navigations[i].children) {
              res = searchNav(navigations[i].children)
            }
            if (res) {
              result = res
              break
            }
          }
        }
        return nav
      }
      searchNav(this.treeOptionNav)
      return result
    },
    findNavByName() {
      const searchNav = navigations => {
        const nav = navigations.filter(item => {
          if (removeDiacritical(item.name).includes(removeDiacritical(this.searchText))) {
            return item
          }
          if (item.children) {
            const resFilter = searchNav(item.children)
            if (resFilter.length > 0) {
              return item
            }
          }
          return null
        })
        return nav
      }
      return searchNav(this.navigations)
    },
    filterNav() {
      this.navigationsTree = this.findNavByName()
    },
    async saveForm() {
      const validate = await this.$refs.navigationRules.validate()
      if (validate) {
        let parenModel = null
        if (this.navigation.parentId) {
          const nav = this.findNavById(this.navigation.parentId)
          parenModel = nav && nav.model
        }
        const payload = {
          parentModel: parenModel || undefined,
          id: this.navigation.id || undefined,
          code: this.navigation.code,
          name: this.navigation.name,
          status: this.navigation.status,
          order: this.navigation.order,
          urlRewrite: this.navigation.urlRewrite,
          iconClass: this.navigation.iconClass,
          applicationId: this.appType ? DICH_VU_CONG : QUAN_TRI_MOT_CUA_ID,
        }
        if (this.navigation.id) {
          this.$axios.put(`${END_POINTS.CHUNG.NAV_LINK}/${this.navigation.id}`, payload, false).then(response => {
            if (response.data && response.data.code === 200) {
              this.$toast.success(response.data.message)
              this.layNavigations()
            } else {
              this.$toast.error(response.data.message)
            }
          })
        } else {
          this.$axios.post(END_POINTS.CHUNG.NAV_LINK, payload, false).then(response => {
            if (response.data && response.data.code === 200) {
              this.$toast.success(response.data.message)
              this.layNavigations()
            } else {
              this.$toast.error(response.data.message)
            }
          })
        }
      } else {
        const first = Object.entries(this.$refs.navigationRules.errors).find(item => item[1].length > 0)
        this.handleFocusError(first)
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        this.$refs[field].focus()
      }
    },
    deleteNavigation() {
      this.$axios.delete(`${END_POINTS.CHUNG.NAV_LINK}/${this.navigation.id}`, null, false).then(response => {
        if (response.data && response.data.code === 200) {
          this.$toast.success(response.data.message)
          this.layNavigations()
          this.resetForm()
        } else {
          this.$toast.error(response.data.message)
        }
      })
    },
    layNavigations() {
      this.$axios.get(END_POINTS.CHUNG.NAV_TREE, false).then(response => {
        if (response.data && response.data.code === 200) {
          this.$store.commit('common/SET_NAV_TREE', response.data.data)
          this.navigationsTree = response.data.data
        }
      })
    },
    changeApp(value) {
      this.appType = value
    },
    setNavItem(nav) {
      this.navigation = _cloneDeep(NAV_MODEL)
      const keysNav = Object.keys(this.navigation)
      keysNav.forEach(key => {
        if (nav[key]) {
          this.navigation[key] = nav[key]
        }
      })
      this.isCreate = false
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation-area {
  padding: 10px;
  border: 1px solid #c0c0c0 !important;
  .nav-title {
    padding: 0 5px;
    width: fit-content;
    margin-top: -20px;
    background-color: white;
    font-weight: 600;
    font-size: 14px;
  }
}

.page-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.button-small {
  padding: 5px 6px !important;
  font-size: 12px !important;
}

.app-disabled {
  background-color: #dcddde !important;
  border-color: #dcddde !important;
}
@media(max-width:1280px){
  .margin-md {
    margin-top: 10px;
  }
}

.search-group {
  padding: 5px 10px;
  .filter-input {
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  svg {
    cursor: pointer;
    margin-top: 8px;
  }
}

.text-tip{
  margin-top:7px;
}

.nav-tree {
  height: 470px;
  overflow: auto;
}
</style>
