<template>
  <div>
    <div
      v-for="nav in options"
      :key="nav.id"
    >
      <div
        :class="{'nav-parent': nav.level === 0, 'nav-item': true, 'd-flex': true}"
        @click="setNavItem(nav.model)"
      >
        <img
          v-if="nav.model.iconClass"
          :src="require(`@/assets/images/menu/${nav.model.iconClass}`)"
          style="width: 20px; height: 20px"
        >
        <div>{{ nav.label }}</div>
      </div>
      <div
        v-if="nav.children"
        class="nav-child"
      >
        <nav-tree
          :options="nav.children"
          :set-nav-item="setNavItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavTree',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    setNavItem: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-parent {
  font-size: 14px;
  font-weight: 600;
  div {
    padding: 2px 0 0 5px;
  }
}

.nav-item {
  cursor: pointer;
}

.nav-child {
  padding-left: 25px;
}
</style>
